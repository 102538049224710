

class Cookie {

    static initCookie() {
        this.setCookie("nome", this.getCookie("nome"));
        this.setCookie("cognome", this.getCookie("cognome"));
        this.setCookie("email", this.getCookie("email"));
        this.setCookie("telefono", this.getCookie("telefono"));
        this.setCookie("via", this.getCookie("via"));
        this.setCookie("citta", this.getCookie("citta"));
        this.setCookie("provincia", this.getCookie("provincia"));
        this.setCookie("cap", this.getCookie("cap"));
    }

    static chiaveValore(chiave) {
        switch (chiave) {

            case "firstName": return "nome";
            case "name": return "cognome";
            case "email": return "email";
            case "phone": return "telefono";


            case "address1": return "via";
            case "city": return "citta";
            case "province": return "provincia";
            case "postalCode": return "cap";

            default: return chiave;
        }
    }

    static getCookie(cname) {
        let name = cname + "=";
        let decodedCookie = decodeURIComponent(document.cookie);
        let ca = decodedCookie.split(';');
        for (let i = 0; i < ca.length; i++) {
            let c = ca[i];
            while (c.charAt(0) === ' ') {
                c = c.substring(1);
            }
            if (c.indexOf(name) === 0) {
                return c.substring(name.length, c.length);
            }
        }
        if (localStorage.getItem(cname) !== null)
            return localStorage.getItem(cname) === null ? "" : localStorage.getItem(cname)

        return "";
    }

    static setCookie(key, value) {
        const now = new Date();
        now.setTime(now.getTime() + 100 * 60 * 60 * 24 * 1000);
        if (typeof window !== 'undefined') {
            document.cookie = `${key}=${value}; expires=${now.toUTCString()}; path=/`;
            localStorage.setItem(key, value) //aggiungi anche su localStorage
        }
    };
}

export default Cookie