import Cookie from "./src/class/Cookie";
import "./src/styles/global.css"

import Track from "./src/class/Track"


sessionStorage.setItem("loadScript", 0);

export const onPreRouteUpdate = (location, preLocation) => {
    sessionStorage.setItem("loadScript", parseInt(sessionStorage.getItem('loadScript')) + 1);
}

export const onClientEntry = () => {
    Cookie.initCookie();
}

//push event Change_Page
export const onRouteUpdate = () => {
    const track = new Track(Track.Method.DATALAYER);
    track.pushTrack('change_page', null);
  }