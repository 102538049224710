class Data_Layer {


    static formatItemView(item) {
        return item;
      }

      
    static formatItem(item) {
            return {
                item_name: item.name,
                item_id: item.id,
                price: item.price,
                quantity: item.quantity,
            };
    }

    static formatItems(items) {
        return items.map(function (item, index) {
            return {
                item_name: item.name,
                item_id: item.id,
                price: item.price,
                quantity: item.quantity,
                index: index + 1
            };
        });
    }

    static formatItemsOrder(items) {
        var r = [];
        var index = 0;
        items.items.forEach(key => {
            r.push({
                item_name: key.name,
                item_id: key.id,
                price: key.totalPriceWithoutDiscountsAndTaxes,
                quantity: key.quantity,
                index: index + 1
            });
            index += 1;
        });
        console.log(r);
        return r;
    }

    static createObj(event, items, ecommerce = null) {
        if (ecommerce !== null) {
            return {
                event: event,
                ecommerce: ecommerce
            }
        }

        return {
            event: event,
            ecommerce: {
                items: items
            }
        }
    }

    static push(obj) {
        if (window.dataLayer !== undefined){
            window.dataLayer.push({ ecommerce: null }); 
            window.dataLayer.push(obj)
        }
            
    }




}

export default Data_Layer;
