import Data_Layer from "./Data_Layer";
import Zaraz from "./Zaraz";

const METHOD = {
  DATALAYER: 0,
  ZARAZ: 1,
};


class Track {

  constructor(method = METHOD.DATALAYER) {
    this.method = method;
  }

  static get Method() {
    return METHOD;
  }

  static createObj(event, items, ecommerce = null) {
    if (this.method === METHOD.DATALAYER) return
    if (this.method === METHOD.ZARAZ) return Zaraz.createObj(event, items, ecommerce)
  }

  //view item
  formatItemView(item) {
    if (this.method === METHOD.DATALAYER) return Data_Layer.formatItemView(item)
    if (this.method === METHOD.ZARAZ) return Zaraz.formatItemView(item)
  }

  //formatta x add to cart e remove to cart
  formatItem(item) {
    if (this.method === METHOD.DATALAYER) return Data_Layer.formatItem(item)
    if (this.method === METHOD.ZARAZ) return Zaraz.formatItem(item)
  }

  //formatta per begin checkout
  formatItems(items) {
    if (this.method === METHOD.DATALAYER) return Data_Layer.formatItems(items)
    if (this.method === METHOD.ZARAZ) return Zaraz.formatItems(items)
  }

  //formatta per begin checkout
  formatItemsOrder(items) {
    if (this.method === METHOD.DATALAYER) return Data_Layer.formatItemsOrder(items)
    if (this.method === METHOD.ZARAZ) return Zaraz.formatItemsOrder(items)
  }

  pushEcommerce(event, items, ecommerce = null) {
    if (this.method === METHOD.DATALAYER) {
      const obj = Data_Layer.createObj(event, items, ecommerce)
      console.log("Data_Layer.push " + event);
      console.log( obj);
      Data_Layer.push(obj);
    }
    if (this.method === METHOD.ZARAZ) {
      const obj = ecommerce === null ? items : ecommerce
      const eventName = Zaraz.convertZarazEvent(event)
      console.log("Zaraz.push " + eventName);
      console.log(obj);
      Zaraz.push(eventName, obj);
    }

  }

  pushTrack(event, items) {
    if (this.method === METHOD.DATALAYER) {
      const obj = Data_Layer.createObj(event, items)
      Data_Layer.push(obj);
      console.log(obj);
    }
    if (this.method === METHOD.ZARAZ) {
      // const eventName = Zaraz.convertZarazEvent(event)
      Zaraz.track(event, items);
    }
  }


}

export default Track;
