module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Beeing","start_url":"/","background_color":"#018f8c","theme_color":"#018f8c","display":"minimal-ui","icon":"src/images/icon.png","lang":"it","cache_busting_mode":"none","theme_color_in_head":false,"legacy":true,"crossOrigin":"anonymous","include_favicon":true,"cacheDigest":null},
    },{
      plugin: require('../node_modules/gatsby-plugin-styled-components/gatsby-browser.js'),
      options: {"plugins":[],"transpileTemplateLiterals":false,"displayName":true,"fileName":true,"minify":true,"namespace":"","topLevelImportPaths":[],"pure":false,"disableVendorPrefixes":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-snipcart-advanced/gatsby-browser.js'),
      options: {"plugins":[],"version":"3.7.2","publicApiKey":"MjBmN2ViOGQtYjhmMC00ZmY3LWFmOTktZmY3NDZlYzVjYzQyNjM4MjI1MDkxNzk0Mzg3Mjk3","loadStrategy":"on-user-interaction","autopop":false,"styles":false,"defaultLang":"it","currency":"eur","openCartOnAdd":true,"useSideCart":true,"locales":{"it":{"confirmation":{"thank_you_for_your_order":"Grazie per la tua adozione! Presto riceverai sulla email che hai indicato il certificato di adozione. Se non la dovessi vedere controlla nella casella SPAM e segnala l'indirizzo come NON SPAM, così da ricevere anche le prossime comunicazioni."},"actions":{"checkout":"Concludi l'ordine"},"cart_summary":{"taxes":"IVA"},"address_form":{"firstName":"Nome","lastName":"Cognome","email":"Email","phone":"Telefono"},"discounts":{"title":"Coupon"}}}},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
