class Zaraz {


  static formatItemView(item) {
    return {
      product_id: item.items[0].id,
      name: item.items[0].name,
      price: item.items[0].price,
      value: item.items[0].price,
      currency: 'EUR',
    };
  }

  static formatItem(item) {
    return {
      product_id: item.id,
      name: item.name,
      price: item.price,
      quantity: item.quantity,
      currency: 'EUR',
      // index: index + 1
    };
  }

  static formatItems(items) {
    return items.map(function (item, _) {
      return {
        name: item.name,
        product_id: item.id,
        price: item.price,
        quantity: item.quantity,
        total: item.price,
        currency: 'EUR',
        // index: index + 1
      };
    });
  }

  static formatItemsOrder(items) {
    console.log('formatItemsOrder')
    var r = [];
    var index = 0;

    if (items.items === undefined) {
      
      items.forEach(key => {
        r.push({
          product_id: key.id,
          name: key.name,
          price: key.totalPriceWithoutDiscountsAndTaxes,
          quantity: key.quantity,
          // total: key.totalPrice
          // index: index + 1
        });
        index += 1;
      });
    }
    else {
      

      items.items.forEach(key => {
        r.push({
          product_id: key.id,
          name: key.name,
          price: key.totalPriceWithoutDiscountsAndTaxes,
          quantity: key.quantity,
          // total: key.totalPrice
          // index: index + 1
        });
        index += 1;
      });
    }


    console.log(r);
    return r;
  }

  static push(event, obj) {
    // console.log("---------ZARAZ PUSH-------------")
    // console.log(event)
    // console.log(obj)
    if (window.zaraz !== undefined) {
      window.zaraz.ecommerce(event, obj);
    }
    else {
      console.log("window.zaraz ERROR!!")
    }
  }

  static track(event, obj) {
    if (window.zaraz !== undefined) {
      window.zaraz.track(event, obj);
    }
  }

  static convertZarazEvent(event) {
    switch (event) {
      case 'begin_checkout':
        return 'Checkout Started';
      case 'add_to_cart':
        return 'Product Added';
      case 'remove_from_cart':
        return 'Product Removed';
      case 'purchase':
        return 'Order Completed';
      case 'view_item':
        return 'Product Viewed';
      default:
        return event;
    }
  }

}

export default Zaraz;
